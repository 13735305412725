<template>
  <div class="expert">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="init"
      v-if="list.length > 0"
    >
      <ul>
        <li v-for="(t, i) in list" :key="i">
          <router-link
            class="li"
            :to="{
              path: '/H5/art/authorDetails',
              query: {
                id: t.Id,
                type: 2,
              },
            }"
          >
            <img :src="t.HeadImg" alt="" srcset="" />
            <div class="intr">
              <p>{{ t.ArtistName }}</p>
              <p class="decimalPoint-2">{{ t.BriefIntroduce }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </van-list>
    <div v-else>
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import { queryArtist } from "@/api/fineArts";
import noData from "@/components/noData";
export default {
  name: "index",
  components: {
    noData,
  },
  computed: {},
  watch: {},
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    // 列表数据
    async init () {
      let parm = {}
      parm.pageIndex = this.page;
      parm.pageSize = this.pageSize;
      const res = await queryArtist(parm);
      if (res.success == true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  }
};
</script>
<style lang="less" scoped>
.expert {
  padding: 1px 15px 15px;
  min-height: 100vh;
  background-color: #fff;
  ul {
    li {
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border-bottom: none;
      }
    }
    .li {
      display: flex;
      // justify-content: space-between;
      margin: 15px 0 0;
      padding-bottom: 15px;
      img {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        // background: #e4e4e4;
        margin-right: 15px;
      }
      p {
        color: #333;
        line-height: 1.5;
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 800;
        }
        &:nth-child(2) {
          color: #6b666b;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
